import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Builder from "../components/builder"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const {
    builder,
    metaTitle,
    metaDescription,
    metaImage,
  } = data.swapi.aboutSingleton
  return (
    <Layout pageContext={pageContext} className="page_about">
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImage ? metaImage.path : ""}
        lang="pl"
      />
      <Builder data={builder} pageContext={pageContext} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query aboutSingletonPL {
    swapi {
      aboutSingleton(lang: "pl") {
        builder
        metaTitle
        metaDescription
        metaImage {
          path
        }
      }
    }
  }
`
